<template>
<div class="card-box-full">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td align="left">
          Number Override
        </td>
        <td align="right">
          <a v-if="currentStep > 1 && !isSubmitting && !finishedSubmitting" v-on:click.prevent="currentStep--" href="">previous step</a>
        </td>
      </tr>
    </table>


  </div>
  <div style="height: 15px"></div>

  <!-- --------------------------------------------------- -->
  <div v-if="finishedSubmitting">
    <h2>Thank You</h2>
    <div>Your override request has been submitted for review. We will contact you on Slack once your request has been processed. If you need the request expedited, you can reach out to us in the <b>#teltech_command_center</b> slack channel.</div>

    <CButton @click="reset()" color="info" style="margin-top: 15px">
      Override more numbers
    </CButton>
  </div>

  <!-- --------------------------------------------------- -->
  <div v-else-if="currentStep == 1">
    <h6>Enter the phone numbers you wish to override</h6>
    <textarea v-model="numberInput" @paste="onPastedNumbers" placeholder="We'll try and intelligently parse any phone numbers from text that you paste here" style="width: 100%; height: 250px; font-family: monospace;"></textarea>
    <CButton @click="clickedStep1()" color="info" style="width: 100%; margin-top: 5px">
      Continue to the next step
    </CButton>
  </div>

  <!-- --------------------------------------------------- -->
  <div v-else-if="currentStep == 2">
    <h6 v-if="parsedNumbers.length == 1">How should this number be treated?</h6>
    <h6 v-else>How should these {{ parsedNumbers.length }} numbers be treated?</h6>

    <table style="margin-top: 10px" cellpadding="5">
      <tr>
        <td>
          <CButton @click="clickedStep2('block')" color="danger" style="width: 100%;">
            Block List
          </CButton>
        </td>
        <td style="padding-left: 10px">
          <span v-if="parsedNumbers.length == 1">This number should <b>always</b> be on the block list and <b>never</b> on the allow list</span>
          <span v-else>These {{ parsedNumbers.length }} numbers should <b>always</b> be on the block list and <b>never</b> on the allow list</span>
        </td>
      </tr>
      <tr>
        <td>
          <CButton @click="clickedStep2('allow')" color="success" style="width: 100%;">
            Allow List
          </CButton>
        </td>
        <td style="padding-left: 10px">
          <span v-if="parsedNumbers.length == 1">This number should <b>always</b> be on the allow list and <b>never</b> on the block list</span>
          <span v-else>These {{ parsedNumbers.length }} numbers should <b>always</b> be on the allow list and <b>never</b> on the block list</span>
        </td>
      </tr>
      <tr>
        <td>
          <CButton @click="clickedStep2('neither')" color="secondary" style="width: 100%;">
            Neither
          </CButton>
        </td>
        <td style="padding-left: 10px">
          <span v-if="parsedNumbers.length == 1">This number should <b>never</b> be on the block or allow list</span>
          <span v-else>These {{ parsedNumbers.length }} numbers should <b>never</b> be on the block or allow list</span>
        </td>
      </tr>
    </table>
  </div>

  <!-- --------------------------------------------------- -->
  <div v-else-if="currentStep == 3">

    <h6 v-if="parsedNumbers.length == 1">Enter a short note describing what this number is</h6>
    <h6 v-else>Enter a short note describing what these {{ parsedNumbers.length }} numbers are</h6>

    <input type="text" placeholder="Example: Some Company Inc."@keyup.enter="clickedStep3()" v-model="noteInput" maxlength="100" style="width: 100%"></input>

    <CButton :disabled="noteInput.length == 0" @click="clickedStep3()" color="info" style="width: 100%; margin-top: 15px">
      Continue to the next step
    </CButton>

  </div>

  <!-- --------------------------------------------------- -->
  <div v-else-if="currentStep == 4">
    <h6 v-if="parsedNumbers.length == 1">Please review this number before submitting the override request</h6>
    <h6 v-else>Please review these {{ parsedNumbers.length }} numbers before submitting the override request</h6>

    <table border="1" width="100%" style="margin-top: 15px" cellpadding="5">
      <tr>
        <td valign="top" width="150"><b>Note</b></td>
        <td><i>{{ noteInput }}</i></td>
      </tr>
      <tr>
        <td valign="top"><b>Override</b></td>
        <td>
          <div v-if="selectedChoice == 'block'">
            <CButton color="danger" disabled>
              Block List
            </CButton>
          </div>
          <div v-else-if="selectedChoice == 'allow'">
            <CButton color="success" disabled>
              Allow List
            </CButton>
          </div>
          <div v-else-if="selectedChoice == 'neither'">
            <CButton color="secondary" disabled>
              Neither
            </CButton>
          </div>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <b v-if="parsedNumbers.length == 1">Phone Number</b>
          <b v-else>Phone Numbers</b>
        </td>
        <td style="font-family: monospace;">
          <div v-for="number in parsedNumbers">{{ number }}</div>
        </td>
      </tr>
    </table>

    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 0px" role="alert">{{ errorMessage }}</div>
    </div>

    <CButton @click="submitRequest()" color="info" style="width: 100%; margin-top: 15px" :disabled="isSubmitting">
      <div v-if="isSubmitting" class="spinner-border spinner-border-sm text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div v-else>Submit override request</div>
    </CButton>

  </div>

</div>
</template>


<script>
export default {
  name: 'Number-Override',
  data() {
    return {
      currentStep: 1,
      errorMessage: null,
      finishedSubmitting: false,
      isSubmitting: false,
      noteInput: "",
      numberInput: "",
      parsedNumbers: [],
      selectedChoice: ""
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    reset() {
      this.currentStep = 1;
      this.errorMessage = null;
      this.finishedSubmitting = false;
      this.isSubmitting = false;
      this.noteInput = "";
      this.numberInput = "";
      this.parsedNumbers = [];
      this.selectedChoice = "";
    },
    //--------------------------------------------------------------------------
    checkPlusOne() {

      var tempNumbers = [];

      // Parse the numbers
      var missingPlusOne = false;
      this.numberInput = this.parseNumbers(this.numberInput);
      var numbers = this.numberInput.split('\n');
      for (let number of numbers) {
        if (number.substring(0, 2) != "+1") {
          missingPlusOne = true;
        }
      }

      // Make sure we have numbers
      if (this.numberInput.length == 0) {
        return;
      }

      // Check if we should add +1 to all the numbers
      if (missingPlusOne) {
        let addPlusOne = confirm("Should all the phone numbers begin with +1 ?");
        if (addPlusOne) {
          for (let number of numbers) {
            if (number.substring(0, 2) != "+1") {
              // Remove non-numeric
              number = "+1" + number.replace(/[^0-9]/g, '');
            }
            tempNumbers.push(number);
          }
        } else {
          tempNumbers = numbers;
        }
      } else {
        tempNumbers = numbers;
      }

      // Update the input field
      var output = "";
      for (let number of tempNumbers) {
        output += number + "\n";
      }
      this.numberInput = output.trim();


    },
    //--------------------------------------------------------------------------
    onPastedNumbers(evt) {
      var text = evt.clipboardData.getData('text/plain');
      this.numberInput = this.parseNumbers(text);
      evt.preventDefault();
      window.setTimeout(this.checkPlusOne, 100);
    },
    //--------------------------------------------------------------------------
    parseNumbers(text) {
      var output = "";
      // Parse the text line by line
      var lines = text.split('\n');
      for (let line of lines) {

        // Remove non numeric except for spaces
        line = line.replace(/[^0-9\s]/g, '');

        // Split on whitespace
        var numbers = line.split(/[\s]+/);
        for (let number of numbers) {
          if (number.length > 0) {
            output += "+" + number + "\n";
          }
        }
      }

      return output.trim();
    },
    //--------------------------------------------------------------------------
    clickedStep1() {

      var tempNumbers = [];

      // Parse the numbers
      var missingPlusOne = false;
      this.numberInput = this.parseNumbers(this.numberInput);
      var numbers = this.numberInput.split('\n');
      for (let number of numbers) {
        if (number.substring(0, 2) != "+1") {
          missingPlusOne = true;
        }
      }

      // Make sure we have numbers
      if (this.numberInput.length == 0) {
        return;
      }

      // Check if we should add +1 to all the numbers
      if (missingPlusOne) {
        let addPlusOne = confirm("Should all the phone numbers begin with +1 ?");
        if (addPlusOne) {
          for (let number of numbers) {
            if (number.substring(0, 2) != "+1") {
              // Remove non-numeric
              number = "+1" + number.replace(/[^0-9]/g, '');
            }
            tempNumbers.push(number);
          }
        } else {
          tempNumbers = numbers;
        }
      } else {
        tempNumbers = numbers;
      }

      // Update the input field
      var output = "";
      for (let number of tempNumbers) {
        output += number + "\n";
      }
      this.numberInput = output.trim();

      // Move to the second step
      this.parsedNumbers = tempNumbers;
      this.currentStep = 2;
    },
    //--------------------------------------------------------------------------
    clickedStep2(choice) {

      // Make sure we have a valid choice
      switch (choice) {
        case "block":
          break;
        case "allow":
          break;
        case "neither":
          break;
        default:
          return;
      }

      this.selectedChoice = choice;
      this.currentStep = 3;

    },
    //--------------------------------------------------------------------------
    clickedStep3() {
      this.noteInput = this.noteInput.trim();
      if (this.noteInput.length == 0) {
        return;
      } else {
        this.currentStep = 4;
      }
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    submitRequest() {

      // Make sure we have numbers
      if (this.parsedNumbers.length == 0) {
        this.currentStep = 1;
        return;
      }

      // Make sure we have an action
      var classify = false;
      var block = false;
      switch (this.selectedChoice) {
        case "block":
          classify = true;
          block = true;
          break;
        case "allow":
          classify = true;
          block = false;
          break;
        case "neither":
          classify = false;
          block = false;
          break;
        default:
          this.currentStep = 2;
          return;
      }

      // Make sure we have a note
      if (this.noteInput.length == 0) {
        this.currentStep = 3;
        return;
      }

      if (this.isSubmitting) {
        return;
      } else {
        this.isSubmitting = true;
        this.errorMessage = null;
      }

      var body = JSON.stringify({
        note: this.noteInput,
        classify: classify,
        block: block,
        phone_numbers: this.parsedNumbers
      });
      var that = this;
      var method = "POST";
      var url = "https://number-classifier.platform.robocalls.ai/override";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            that.isSubmitting = false;
            that.finishedSubmitting = true;
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isSubmitting = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isSubmitting = false;
          that.errorMessage = error;
        }
      })

    }
  },
  mounted() {},
}
</script>
